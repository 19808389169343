import PluginNotFound from '@/core/domain/exception/PluginNotFound'

export default class PluginFactory {
  static async build (project: string, pluginName: string, context: any) {
    let plugin = null
    try {
      plugin = await import(`@/plugins/${project}/infrastructure/plugins/${pluginName}`)
    } catch {
      try {
        plugin = await import(`@/plugins/${project}/${pluginName}`)
      } catch {
        throw new PluginNotFound(`Plugin: ${pluginName} not found`)
      }
    }
    return new plugin.default(context)
  }
}
